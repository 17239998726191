<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="50%"
            :center="true"
            top="10vh"
            :close-on-click-modal="false"
            :before-close="() => closeClientDialog('ClientEditForm')"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="Client"
                    :rules="rules"
                    ref="ClientEditForm"
                    label-width="120px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="企业名称" prop="name">
                            <el-input v-model="Client.name" clearable></el-input>
                        </el-form-item>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="企业类型" prop="type">
                                    <el-select
                                        v-model="Client.type"
                                        placeholder="选择类型"
                                        class="enterprisec"
                                        clearable
                                    >
                                        <el-option
                                            label="客户"
                                            :value=0
                                        ></el-option>
                                        <el-option
                                            label="供应商"
                                            :value=1
                                        ></el-option>
                                        <el-option
                                            label="我方企业"
                                            :value=2
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="合作状态" prop="status">
                                    <el-select
                                        v-model="Client.status"
                                        placeholder="选择状态"
                                        class="statusc"
                                        clearable
                                    >
                                        <el-option
                                            label="禁用"
                                            :value=0
                                        ></el-option>
                                        <el-option
                                            label="临时"
                                            :value=1
                                        ></el-option>
                                        <el-option
                                            label="长期"
                                            :value=2
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="联系人" prop="contacts">
                                    <el-input v-model="Client.contacts" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="Client.phone" clearable></el-input>
                            </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="企业地址" prop="address">
                            <el-input type="textarea" :rows="3" v-model="Client.address"></el-input>
                        </el-form-item>
                        <el-form-item label="所属我方企业" prop="with_zhwl_enterprise">
                            <el-select
                                v-model="Client.with_zhwl_enterprise"
                                filterable
                                clearable
                                placeholder="选择我方企业"
                                class="with_zhwl_enterprisec"
                            >
                                <el-option
                                    v-for="enterprise in AllEnterprise"
                                    :key="enterprise.id"
                                    :label="enterprise.name"
                                    :value="enterprise.name"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注" prop="note">
                            <el-input type="textarea" :rows="5" v-model="Client.note"></el-input>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>企业名称</span>
                                </template>
                                <span>{{Client.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>企业类型</span>
                                </template>
                                <span v-if="Client.type === 0">客户</span>
                                <span v-else-if="Client.type === 1">供应商</span>
                                <span v-else-if="Client.type === 2">我方企业</span>
                                <span v-else></span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>合作状态</span>
                                </template>
                                <el-tag v-if="Client.status === 0" type="danger">禁用</el-tag>
                                <el-tag v-else-if="Client.status === 1" type="warning">临时</el-tag>
                                <el-tag v-else-if="Client.status === 2" type="primary">长期</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>联系人</span>
                                </template>
                                <span>{{Client.contacts}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>联系电话</span>
                                </template>
                                <span>{{Client.phone}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属我方企业</span>
                                </template>
                                <span>{{Client.with_zhwl_enterprise}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2>
                                <template slot="label">
                                    <span>企业地址</span>
                                </template>
                                <span>{{Client.address}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item :span=2 :contentStyle="{'text-align':'left'}">
                                <template slot="label">
                                    <span>备注</span>
                                </template>
                                <span>{{Client.note}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <!-- <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="ClientDel('ClientEditForm')"
                        >删除</el-button
                    > -->
                    <el-button
                        plain
                        @click="closeClientDialog('ClientEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('ClientEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm('ClientEditForm')"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeClientDialog('ClientEditForm')"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('ClientEditForm')"
                        >确定修改</el-button
                    >
                    <el-button plain @click="closeClientDialog('ClientEditForm')"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { client_add_request,client_del_request,client_edit_request } from '@/network/business/client.js'

export default {
    name: 'ClientEditComponent',
    data() {
        return {
            login_loading: false,
            Client: {
                name: '',
                type: '',
                status: '',
                address: '',
                contacts: '',
                phone: '',
                note: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入企业名称',
                        trigger: 'blur',
                    },
                ],
                type: [
                    {
                        required: true,
                        message: '请选择企业类型',
                        trigger: 'blur',
                    },
                ],
                status: [
                    {
                        required: true,
                        message: '请选择合作状态',
                        trigger: 'blur',
                    },
                ],
                with_zhwl_enterprise: [
                    {
                        required: true,
                        message: '请选择所属我方企业',
                        trigger: 'blur',
                    },
                ],
                address: [
                    {
                        required: true,
                        message: '请填写企业地址',
                        trigger: 'blur',
                    },
                ],
                contacts: [
                    {
                        required: true,
                        message: '请填写联系人',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '请填写联系电话',
                        trigger: 'blur',
                    },
                ],
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '企业详情'
            } else if (this.show_type === 1) {
                return '添加企业'
            } else {
                return '修改企业'
            }
        },
    },
    methods: {
        closeClientDialog(formName) {
            this.resetForm(formName)
            this.$emit('exitClientDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ClientInsert(formName)
            })
        },
        ClientInsert(formName) {
            this.login_loading = true
            let d = this.Client
            client_add_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitClientDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm(formName) {
            this.Client = {
                name: '',
                type: '',
                status: '',
                address: '',
                contacts: '',
                phone: '',
                note: ''
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.ClientEdit(formName)
            })
        },
        ClientEdit(formName) {
            this.login_loading = true
            this.Client.id = this.id
            let d = this.Client
            client_edit_request(d)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitClientDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.Client.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        ClientDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    client_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeClientDialog(formName))
                })
                .catch((err) => {})
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        client_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String,
        AllEnterprise: {
            type:Array,
            default() {
                return []
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        client_details_data(newVal) {
            this.Client = newVal
        }
    },
}
</script>

<style lang='less'>
.enterprisec,.statusc,.with_zhwl_enterprisec {
    width: 100%;
}
</style>